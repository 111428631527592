import React, { useState, useEffect, useRef } from "react"
import { withNamespaces } from "react-i18next"
import axios from "axios"
import limpiarTexto from "../../../components/xMediaBlogArticle/utilsBlogs"
import TemplateBlogArticle from "../../../components/xMediaBlogArticle/TemplateBlogArticle"
import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import BannerBlogArticle from "../../../components/xMediaBlogArticle/BannerBlogArticle"

function EnglishBlogArticles(props) {
  const [fetchedBlog, setFetchedBlogs] = useState(null)
  const blogName = props.params.englishBlogTitle
  const languageBlog = "en"

  useEffect(() => {
    let isMounted = true
    async function fetchJobDetails() {
      try {
        if (blogName && isMounted) {
          const ref = await axios.get("https://exsis.cdn.prismic.io/api/v2")
          const resBlogs = await axios.get(
            `https://exsis.prismic.io/api/v2/documents/search?ref=${ref.data.refs[0].ref}&lang=en-us`
          )
          const blogs = resBlogs.data
          setFetchedBlogs(blogs)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchJobDetails()
  }, [blogName])

  const blogDetails =
    fetchedBlog &&
    fetchedBlog.results.filter(
      blog => limpiarTexto(blog.data.blog_title[0].text) === blogName
    )[0]

  return (
    <Layout>
      <SEO
        title={`${(blogDetails && blogDetails.data.blog_title[0].text) ||
          "No Title"}  | Exsis Digital Angels`}
        description={"Nearshore Agile Teams | Specialists in Tech Talent"}
      />
      <BannerBlogArticle
        titleBanner={`${(blogDetails && blogDetails.data.blog_title[0].text) ||
          "No Title"}`}
      />
      <TemplateBlogArticle
        ContentBlog={blogDetails}
        fetchedBlog={fetchedBlog && fetchedBlog.results}
        languageBlog={languageBlog}
      />
    </Layout>
  )
}

export default withNamespaces()(EnglishBlogArticles)
